/** 市场统计项目详情 */
<template>
  <div class="market-details">
    <ul class="project-details">
      <li class="details-item flex">
        <label>项目名称：</label>
        <span>{{data.project}}</span>
      </li>
      <li class="details-item flex">
        <label>原始权人：</label>
        <span>{{data.project_person}}</span>
      </li>
      <li class="details-item flex">
        <label>所在地区：</label>
        <span>{{data.area}}</span>
      </li>
      <li class="details-item flex">
        <label>发行场所：</label>
        <span>{{data.place}}</span>
      </li>
      <li class="details-item flex">
        <label>发行规模：</label>
        <span>{{data.scale}}</span>
      </li>
      <li class="details-item flex">
        <label>发行日期：</label>
        <span>{{data.release_date}}</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'MarketDetails',
  data () {
    return {
      data: this.$route.query
    }
  },
}
</script>
<style lang="scss" scoped>
.details-item {
  padding: 20px;
  font-size: 14px;
  line-height: 20px;
  &:nth-child(even) {
    background-color: #f1f2fa;
  }
  label {
    min-width: 80px;
  }
}
</style>